import "../assets/styles/components.css"
import footerLogo from '../assets/images/logo-white.png'


const Footer = () => {
    return(
        <footer>
            <div className="wrapper">
                <div className="footer-container">
                    <div className="footer-column one">
                        <div className="footer-logo">
                            <img src={footerLogo} alt="footer-logo" />
                        </div>
                        
                    </div>
                    <div className="footer-column two">
                        <div className="column-elements">
                            <label >Endereço:</label>
                            <p>Rua Antônio Alves Amorim <br /> nº 51 - Saudade, Barra Mansa <br />RJ - 27313-060</p>
                            <label>Horário de funcionamento:</label>
                            <p>De segunda à sexta-feira, das 08h até 18h</p>
                            <label>Email:</label>
                            <p>contato@graficadrumond.com.br</p>
                        </div>
                    </div>
                    <div className="footer-column three">
                        <div className="column-elements">
                            <label>CNPJ:</label>
                            <p>35.872.118/0001-94</p>
                            <label>Razão Social:</label>
                            <p>Gráfica e Editora Irmãos Drumond Ltda</p>
                            <label>Telefones:</label>
                            <p>(24) 3325-8024 (WhatsApp)</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer