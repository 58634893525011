import "../assets/styles/components.css"

import {faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons'

import Nav from "./Nav"

import Logo from '../assets/images/logo.png'
import Icons from "./Icons"
import Button from "./Button"
import { useEffect } from "react"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"


const Header = () => {

    useEffect(() => {
        fixMenu()
    }, [])

    function fixMenu() {
        window.addEventListener('scroll', () => {
            let menu = document.querySelector('.menu')
            let afterMenu = document.querySelector('.slider')
            let afterMenuTop = afterMenu.getBoundingClientRect().top
            let menuTop = menu.getBoundingClientRect().top
            if(menuTop < 0) {
                menu.classList.add('fixed')
            }

            if(afterMenuTop >= 0) {
                menu.classList.remove('fixed')
            }
        })
    }

    return(
        <header>
            <div id="inicio" className="top-bar">
                <div className="logo-mobile">
                    <img src={Logo} />
                </div>
                <div className="wrapper">
                    <div className="logo">
                        <img src={Logo} />
                    </div>
                    <Icons link="https://api.whatsapp.com/send/?phone=552433258024&amp;text&amp;type=phone_number&amp;app_absent=0" icon={faWhatsapp} text="(24) 3325-8024" subText="WhatsApp" size={30} width={30}/>
                    <Icons link="mailto: contato@graficadrumond.com.br" icon={faEnvelope} text="contato@graficadrumond.com.br" subText="Fale conosco" size={30} width={30}/>
                    <Button text="Orçamento" backgroundColor="#02639b" color="white" target="_blank" link="https://api.whatsapp.com/send/?phone=552433258024&amp;text&amp;type=phone_number&amp;app_absent=0"/>
                </div>
                
            </div>
            <div className="menu">
                <div className="wrapper">
                    <Nav/>  
                </div>
                
            </div>
        </header>
    )
}

export default Header