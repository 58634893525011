import aboutUsImg1 from '../assets/images/about-us1.jpg'
import aboutUsImg2 from '../assets/images/about-us5.jpg'
import aboutUsImg3 from '../assets/images/about-us3.png'
import aboutUsImg4 from '../assets/images/about-us4.jpg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruckFast, faMicrochip, faUsersRectangle, faSeedling } from '@fortawesome/free-solid-svg-icons'

import Button from './Button'

const AboutUs = () => {
    return(
        <div id="drumond" className="about-us">
            
            <div className="wrapper">
                <div className="grafica-drumond">
                    <div className="about-us-img">
                        <div className="pollaroid-container">
                            <div className="pollaroid-style">
                                <img src={aboutUsImg1} alt="foto1" />
                            </div>
                            <div className="pollaroid-style">
                                <img src={aboutUsImg2} alt="foto2" />
                            </div>
                            <div className="pollaroid-style">
                                <img src={aboutUsImg3} alt="foto3" />
                            </div>
                            <div className="pollaroid-style">
                                <img src={aboutUsImg4} alt="foto4" />
                            </div>
                        </div>
                    
                    </div>
                    <div className="about-us-text animation fade-in">
                        <div className="about-us-texts">
                            <h2>Gráfica Drumond</h2>
                            <p>Conhecida por ser uma gráfica ágil na entrega dos serviços e com qualidade de impressão, a GRÁFICA DRUMOND cada vez mais vem conquistando mercado em toda região sudeste.</p>
                            <p>Desde o ano de sua fundação em 1989, a empresa sempre buscou atualizar seus processos de produção investindo em novas tecnologias gráficas. Possui um parque gráfico moderno, com máquinas de impressão offset capazes de rodar impressos coloridos de grandes tiragens com agilidade e qualidade. Além disso, a <b>GRÁFICA DRUMOND</b> busca ter uma relação diferenciada com seus clientes e fornecedores, sabendo ouvir seus anseios e desejos a fim de entregar melhores resultados.</p>
                            <div className="about-us-buttons">
                                <Button text="CONHEÇA NOSSOS SERVIÇOS" backgroundColor="" borderColor="#02639b" color="#02639b" link="#servicos"/>
                                <Button text="CONTATO" backgroundColor="#02639b" borderColor="#02639b" color="white" link="#contato"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="visao-valores">
                    <h2>Missão</h2>
                    <p>Promover nossos clientes através da tecnologia de impressão gráfica, com excelência, agilidade e sustentabilidade.</p>
                    <h2>Visão</h2>
                    <p>Ser uma gráfica de referência na região sul fluminense pela excelência na prestação dos serviços.</p>

                </div>
                <div className="drumond-destaques">
                    <div className="destaque">
                        <div className="destaque-title">
                            <FontAwesomeIcon icon={faMicrochip} fontSize={30} color='#02639b'/>
                            <h3>TECNOLOGIA GRÁFICA AVANÇADA:</h3>
                        </div>
                        
                        <p>A Gráfica Drumond destaca-se pelo seu parque gráfico moderno, equipado com máquinas de impressão offset de última geração. Isso permite a produção ágil e de alta qualidade de impressos coloridos em grandes tiragens. O investimento contínuo em novas tecnologias gráficas demonstra o compromisso da empresa em permanecer atualizada e oferecer soluções de impressão inovadoras aos seus clientes.</p>
                    </div>
                    <div className="destaque">
                        <div className="destaque-title">
                            <FontAwesomeIcon icon={faTruckFast} fontSize={30} color='#02639b'/>
                            <h3>AGILIDADE NA ENTREGA:</h3>
                        </div>
                        
                        <p>A Gráfica Drumond é conhecida por sua agilidade na entrega dos serviços. Isso sugere um processo de produção otimizado e eficiente, que permite atender prazos desafiadores sem comprometer a qualidade. A Gráfica Drumond valoriza a satisfação do cliente ao cumprir os prazos de entrega, o que é essencial em um mercado competitivo.</p>
                    </div>
                    
                    <div className="destaque">
                        <div className="destaque-title">
                            <FontAwesomeIcon icon={faUsersRectangle} fontSize={30} color='#02639b'/>
                            <h3>FOCO NO RELACIONAMENTO: </h3>
                        </div>
                        <p> A Gráfica Drumond adota uma abordagem diferenciada ao tratar seus clientes e fornecedores como parceiros. Isso reflete o compromisso da empresa em ouvir as necessidades e desejos de seus stakeholders, resultando em resultados mais satisfatórios. Essa relação próxima também pode estimular a colaboração e a troca de ideias para melhorar continuamente os processos e serviços.</p>
                    </div>
                    <div className="destaque">
                        <div className="destaque-title">
                            <FontAwesomeIcon icon={faSeedling} fontSize={30} color='#02639b'/>
                            <h3>SUSTENTABILIDADE E RESPONSABILIDADE: </h3>
                        </div>
                        <p>A Gráfica Drumond busca atuar de forma sustentável, demonstrando preocupação com questões ambientais e sociais. Embora o texto não detalhe isso, a menção à atuação sustentável indica que a Gráfica Drumond está ciente das implicações de suas atividades no meio ambiente e na comunidade. Isso pode incluir práticas de impressão mais ecológicas, redução de resíduos e possivelmente iniciativas de responsabilidade social.</p>
                    </div>
                </div>
                <div className="video-drumond">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/lhYmoAAJrng" frameborder="0" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/RoloSJCIZh4" frameborder="0" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
            </div>
        </div>
    )
}

export default AboutUs