import Slide from "./Slide"
import sliderImg from '../assets/images/slider-img.jpg'
import sliderImg2 from '../assets/images/slider-img2.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { useState, useEffect } from "react"


const Slider = () => {

    const [slideIndex, setSlideIndex] = useState(0)

    useEffect(() => {
        const slides = document.querySelectorAll('.slide');

        slides.forEach((slide, index) => {
        if (index === slideIndex) {
            slide.classList.add('active');
        } else {
            slide.classList.remove('active');
        }
        });

        const interval = setInterval(() => {
            changeSliderIndex(1); 
        }, 5000);
      
        return () => {
            clearInterval(interval);
        };

    }, [slideIndex]);

    function changeSliderIndex(index) {
        const slides = document.querySelectorAll('.slide');
        const newIndex = slideIndex + index;
    
        if (newIndex >= slides.length) {
          setSlideIndex(0);
        } else if (newIndex < 0) {
          setSlideIndex(slides.length - 1);
        } else {
          setSlideIndex(newIndex);
        }
      }

    return(
        <div className="slider">
            <Slide
                img={sliderImg}
                tittle={"Destaque-se com <br>Impressos Personalizados!"}
                text="<b>Transforme sua mensagem em arte visual!</b> Nossos impressos personalizados são a chave para chamar a atenção do seu público. <b>Faça hoje mesmo e deixe sua marca!</b>"
                buttonOneProps={{
                    text: "Peça Agora",
                    link: "https://api.whatsapp.com/send/?phone=552433258024&text&type=phone_number&app_absent=0", 
                    color: "white", 
                    backgroundColor: "#f8c212", 
                    borderColor: "#f8c212",
                }}
                buttonTwoProps={{
                    text: "Ver Modelos",
                    onClick: () => document.querySelector('#servicos').scrollIntoView({behavior: "smooth"}),
                    link: "#servicos",
                    color: "#f8c212", 
                    backgroundColor: "", 
                    borderColor: "#f8c212"
                }}
            />
            <Slide
                img={sliderImg2}
                tittle={"Frete Grátis acima<br> de R$100 para VR, BM e Resende"}
                text="Realizando um pedido acima de R$100 reais, você ganha frete grátis para<br><b>Resende, Volta Redonda e Barra Mansa</b>.</b>"
                buttonOneProps={{
                    text: "Peça Agora",
                    link: "https://api.whatsapp.com/send/?phone=552433258024&text&type=phone_number&app_absent=0", 
                    color: "white", 
                    backgroundColor: "#f8c212", 
                    borderColor: "#f8c212",
                }}
                buttonTwoProps={{
                    text: "Ver Modelos",
                    onClick: () => document.querySelector('#servicos').scrollIntoView({behavior: "smooth"}),
                    link: "#servicos",
                    color: "#f8c212", 
                    backgroundColor: "", 
                    borderColor: "#f8c212"
                }}
            />
            <div className="slider-controls">     
                <div className="prev"><FontAwesomeIcon icon={faChevronLeft} fontSize={35} color="white" onClick={() => changeSliderIndex(-1)}/></div>
                <div className="next"><FontAwesomeIcon icon={faChevronRight} fontSize={35} color="white"onClick={() => changeSliderIndex(1)}/></div>
            </div>
        </div>  
    )
}

export default Slider

